import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { View, Text, TextInput, FlatList, TouchableOpacity } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import Markdown from 'react-native-markdown-display';

// Define the context to handle state transitions
const StateContext = createContext(null);

const extractData = (data, state) => {
  if (data.stateLabels[state]) { // if state exists in stateLabels
    return {
      stateLabel: data.stateLabels[state],
      targetStates: data.targetStates[state],
      transitionLabels: data.targetStates[state].map(target => data.transitionLabels[`${state}-${target}`])
    };
  } else if (!isNaN(state) && state < Object.keys(data.stateLabels).length) { // if state is numeric and within valid index
    const validState = Object.keys(data.stateLabels)[state];
    return {
      stateLabel: data.stateLabels[validState],
      targetStates: data.targetStates[validState],
      transitionLabels: data.targetStates[validState].map(target => data.transitionLabels[`${validState}-${target}`])
    };
  } else if (Object.entries(data.transitionLabels).filter(v => v[1] === state).length > 0) {
    const matchingTransitions = Object.entries(data.transitionLabels).filter(v => v[1] === state);
    return {
      stateLabel: state,
      targetStates: matchingTransitions.map(v => v[0].split('-')[0]),
      transitionLabels: matchingTransitions.map(v => v[0].split('-')[1])
    };
  } else if (data.options && data.options.generateMissingStates) {
      return {
        stateLabel: state,
        targetStates: [],
        transitionLabels: []
      };
  }
  throw new Error(`Specified state '${state}' does not exist`);
}

const assignLabels = (data, state) => {
  try {
    return extractData(data, state);
  } catch (error) {
    return { stateLabel: `Error occurred: <<${error.message}>> \n state <<${state}>> \n data <<${JSON.stringify(data)}>>` };
  }
}

const wiki_link = (url) => `[${url}](${encodeURI(url)})`;
const linkTable = (targetStates, transitionLabels) => {
const header = `
  |______________|______________|
  |--------------|--------------|
`;
const rows = targetStates && targetStates.map((targetState, i) => (
    '| ' + wiki_link(transitionLabels[i]) + ' | ' + wiki_link(targetState) + ' |\n'
));
return rows? header + rows.join('') : '';
}

const StateScreen = ({ navigation }) => {
  const { state, transitionToState, goBack, data } = useContext(StateContext);

  useEffect(() => {
    navigation.setParams({ goBack });
  }, []);

  if (!data) {
    // Show a loading message while data is being fetched
    return <Text>Loading...</Text>;
  }

  const { stateLabel, targetStates, transitionLabels } = assignLabels(data, state);
  const markdown_text = `
  # ${stateLabel}
  ${linkTable(targetStates, transitionLabels)}
  `;

  const onLinkPress = (url) => {
    if (url) {
      transitionToState(decodeURI(url));
    }

    return false; // open with Linking.openURL?
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Markdown onLinkPress={onLinkPress}>
        {markdown_text}
      </Markdown>
    </View>
  );
};

const SearchScreen = ({ navigation }) => {
  const { data, transitionToState } = useContext(StateContext);
  const [searchText, setSearchText] = useState('');

  if (!data) {
    return <Text>Loading...</Text>;
  }

  const filteredStates = Object.keys(data.stateLabels).filter(key =>
    data.stateLabels[key].toLowerCase().includes(searchText.toLowerCase())
  );

  if (searchText && filteredStates.length === 1) {
    transitionToState(filteredStates[0]);
    navigation.goBack();
  }

  return (
    <View>
      <TextInput
        onChangeText={setSearchText}
        value={searchText}
        placeholder="Search state..."
        autoFocus
      />
      <FlatList
        data={filteredStates}
        keyExtractor={item => item}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => {
            transitionToState(item);
            navigation.goBack();
          }}>
            <Text style={{ backgroundColor: 'yellow' }}>{data.stateLabels[item]}</Text>
          </TouchableOpacity>
        )}
      />
    </View>
  );
};


function messageJSON(text) {
  return '{"stateLabels":{"1":"' + text + '"},"targetStates":{"1":[]},"transitionLabels":{}}';
}

function messageObject(error) {
  return { "stateLabels": {"1":error.toString()}, "targetStates":{"1":[]}, "transitionLabels":{} };
}


const helpUrl = 'data:text/plain;charset=UTF-8;,' + messageJSON('Help');

function shaUrl(hash) {
  return 'https://us-central1-ytozus.cloudfunctions.net/sha/' + hash;
}

function mappedURL(url) {
  if (url == null) {
    return null;
  }
  const start = url.indexOf('#');
  if (start < 0) {
    return helpUrl;
  }
  const rest = url.substring(start + 1);
  if (rest.startsWith('data') || rest.startsWith('http') || rest.startsWith('https')) { 
    return rest;
  }
  return shaUrl(rest);
}

const StateProvider = ({ children }) => {
  const [state, setState] = useState(1);
  const [history, setHistory] = useState([]);
  const [data, setData] = useState(null);
  const thisPage = Linking.useURL();
  const stateMachineUrl = mappedURL(thisPage);

  useEffect(() => {
    if (stateMachineUrl == null) {
      setData(messageObject('No URL'));
      return;
    }
    fetch(stateMachineUrl)
      .then(response => response.text())
      .then(responseText => {
        try {
          const data = JSON.parse(responseText);
          setData(data);
        } catch (error) {
          console.error(error);
          setData(messageObject(`ERROR<<${error}>> URL<<${stateMachineUrl}>> TEXT<<${responseText}>>`));
        }
      })
      .catch(error => {
        console.error(error);
        setData(messageObject(`${error} ${stateMachineUrl}`));
      });
  }, [stateMachineUrl]);
  
  const transitionToState = (targetState) => {
    setHistory(prevHistory => [...prevHistory, state]);
    setState(targetState);
  };

  const historyRef = useRef(history);
  historyRef.current = history;

  const goBack = () => {
    const history = historyRef.current;
    if (history.length > 0) {
      const lastState = history[history.length - 1];
      const newHistory = history.slice(0, history.length - 1);
      setState(lastState);
      setHistory(newHistory);
    }
  };

  return (
    <StateContext.Provider value={{ state, transitionToState, goBack, data }}>
      {children}
    </StateContext.Provider>
  );
};

const Stack = createStackNavigator();

export default function App() {
  return (
    <StateProvider>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="StateMachine"
            component={StateScreen}
            options={({ navigation, route }) => ({
              headerLeft: (props) => (
                <FontAwesome.Button name="arrow-left" {...props} onPress={() => route.params?.goBack() }/>
              ),
              headerRight: () => (
                <View style={{ flexDirection: "row" }}>
                  <FontAwesome.Button name="search" onPress={() => navigation.navigate('Search')} />
                  <Text> </Text>
                  <FontAwesome.Button name="question" onPress={() => navigation.navigate('Help')} />
                </View>
              ),
              headerRightContainerStyle: {
                paddingRight: 5,
              },
              headerLeftContainerStyle: {
                paddingLeft: 5,
              },
            })}
          />
          <Stack.Screen name="Search" component={SearchScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </StateProvider>
  );
}
